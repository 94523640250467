var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body fixed mobile"},[_c('Spinner',{attrs:{"spin":_vm.spin}}),_c('section',{staticClass:"header activity_questions"},[_c('a',{staticClass:"icon iconButton third",on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/service')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-left","size":"lg"}})],1),_c('span',{staticClass:"fL nowrap"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"icon iconButton"})]),_c('section',{staticClass:"main activity_questions"},[_c('div',{staticClass:"search-wrap"},[_c('div',{staticClass:"search"},[_c('SearchIcon'),_c('input',{attrs:{"type":"text","placeholder":"搜尋關鍵字"}})],1),_vm._m(0)]),(_vm.campaigns.length > 0)?_c('div',_vm._l((_vm.campaigns),function(campaign,index){return _c('a',{key:'campaign' + index,staticClass:"news flexH width margin between",on:{"click":function($event){$event.preventDefault();return _vm.toDetail(campaign)}}},[_c('div',{staticClass:"uide-mission-card"},[_c('div',{staticClass:"uide-mission-card-top"},[_c('div',{staticClass:"uide-mission-card-top-chip"},[_vm._v(" "+_vm._s(campaign.departmentName)+" ")]),_c('div',[_vm._v(_vm._s(_vm.tsToDate(campaign.campaignStartTime)))])]),_c('div',{staticClass:"uide-mission-card-title"},[_vm._v(" "+_vm._s(campaign.campaignName)+" ")]),_c('div',[_vm._v(" "+_vm._s(campaign.campaignDesc)+" ")]),_c('div',{staticClass:"flex gap-1 items-center"},[_c('img',{attrs:{"width":"20","height":"20","src":require("@/assets/icon/uide/coin.svg")}}),_c('span',[_vm._v("雲林幣"+_vm._s(campaign.point)+"枚")])]),_c('div',{staticClass:"flex gap-1 items-center justify-between"},[_c('ProgressBar',{staticStyle:{"width":"228px"},attrs:{"progress":((campaign.reward_total - campaign.reward_remain) / campaign.reward_total) * 100}}),_c('div',{class:[
                'uide-mission-card-progress-btn',
                'status' + _vm.getStatusId(campaign),
              ]},[_vm._v(" "+_vm._s(_vm.getStatusName(campaign))+" ")])],1),_c('div',{staticClass:"flex gap-1 items-center"},[_c('img',{attrs:{"width":"16","height":"16","src":require("@/assets/icon/uide/people.svg")}}),_c('span',{staticClass:"uide-mission-card-user"},[_vm._v("獎勵剩餘人數 "+_vm._s(campaign.reward_remain)+" 名 / 總人數 "+_vm._s(campaign.reward_total)+" 名")])]),_c('img',{class:[
              'uide-mission-card-bell',
              { hide: !campaign.unread },
            ],attrs:{"src":require("@/assets/icon/bell-v2.svg")}})])])}),0):_c('div',[_c('div',{staticClass:"listEmpty flexV width center middle"},[_c('div',{staticClass:"icon secondary"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-dice-d6","size":"10x"}})],1),_c('span',{staticClass:"fL gray"},[_vm._v("目前沒有"+_vm._s(_vm.title)+"！")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter"},[_c('select'),_c('select')])
}]

export { render, staticRenderFns }